/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-06-03",
    versionChannel: "nightly",
    buildDate: "2023-06-03T00:25:51.214Z",
    commitHash: "2cd518013c74044100407435284e2cf108e96dfe",
};
